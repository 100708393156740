<template>
    <Layout>
        <b-overlay :show="loading">
            <b-form @submit.prevent>
                <div class="card">
                    <div class="card-header">
                        <h4 class="card-title">
                            <div class="d-flex align-items-center">
                                <b-button v-b-tooltip.hover.top="$t('general.back')" size="sm" variant="outline-primary" class="me-2" @click="$router.go(-1)">
                                    <i class="fas fa-long-arrow-alt-left"></i>
                                </b-button>
                                {{ title }}
                            </div>
                        </h4>
                    </div>
                    <div class="card-body">
                        <b-row>
                            <b-col cols="12" md="3">
                                <b-form-group
                                    id="username"
                                    class="row"
                                    :label="$t('api_account.username')"
                                    label-for="username-input"
                                    label-cols="12"
                                    content-cols="12"
                                    :state="errors.username"
                                >
                                    <b-form-input id="username-input" v-model="model.username" :placeholder="$t('api_account.placeholders.username')"></b-form-input>
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" md="3">
                                <b-form-group
                                    id="fullname"
                                    class="row"
                                    :label="$t('api_account.fullname')"
                                    label-for="fullname-input"
                                    label-cols="12"
                                    content-cols="12"
                                    :state="errors.fullname"
                                >
                                    <b-form-input id="fullname-input" v-model="model.fullname" :placeholder="$t('api_account.placeholders.fullname')"></b-form-input>
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" md="3">
                                <b-form-group
                                    id="passwd"
                                    class="row"
                                    :label="$t('api_account.password')"
                                    label-for="passwd-input"
                                    label-cols="12"
                                    content-cols="12"
                                    :state="errors.passwd"
                                >
                                    <b-button v-if="!modifiedPassword" variant="primary" @click="editPassword">{{ $t('api_account.modify_key') }}</b-button>
                                    <b-form-input v-else type="password" id="passwd-input" v-model="model.passwd" :placeholder="$t('api_account.placeholders.password')" @blur="blurPassword"></b-form-input>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row class="mt-4">
                            <b-col cols="12" md="6">
                                <b-form-group
                                    id="domain-ip"
                                    class="row"
                                    :label="$t('api_account.domain_ip')"
                                    label-for="domain-ip-input"
                                    label-cols="12"
                                    content-cols="12"
                                    :state="errors.domain_ip"
                                >
                                    <b-form-input id="domain-ip-input" v-model="model.domain_ip" :placeholder="$t('api_account.placeholders.domain_ip')"></b-form-input>
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" md="6">
                                <b-form-group
                                    id="domain-name"
                                    class="row"
                                    :label="$t('api_account.domain_name')"
                                    label-for="domain-name-input"
                                    label-cols="12"
                                    content-cols="12"
                                    :state="errors.domain_name"
                                >
                                    <b-form-input id="domain-name-input" v-model="model.domain_name" :placeholder="$t('api_account.placeholders.domain_name')"></b-form-input>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row class="mt-4">
                            <b-col cols="12" sm="4" lg="2">
                                <b-form-group
                                    id="is-rest"
                                >
                                    <div class="form-check form-switch form-switch-md mb-2">
                                        <label class="form-check-label" for="is-rest-input">{{ $t('api_account.is_rest') }}</label>
                                        <input type="checkbox" id="is-rest-input" class="form-check-input" v-model="model.is_rest" />
                                    </div>
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" sm="4" lg="2">
                                <b-form-group
                                    id="is-soap"
                                >
                                    <div class="form-check form-switch form-switch-md mb-2">
                                        <label class="form-check-label" for="is-soap-input">{{ $t('api_account.is_soap') }}</label>
                                        <input type="checkbox" id="is-soap-input" class="form-check-input" v-model="model.is_soap" />
                                    </div>
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" sm="4" lg="2">
                                <b-form-group
                                    id="is-sms-utf8"
                                >
                                    <div class="form-check form-switch form-switch-md mb-2">
                                        <label class="form-check-label" for="is-sms-utf8-input">{{ $t('api_account.is_sms_utf8') }}</label>
                                        <input type="checkbox" id="is-sms-utf8-input" class="form-check-input" v-model="model.is_sms_utf8" />
                                    </div>
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" sm="4" lg="2">
                                <b-form-group
                                    id="is-active"
                                >
                                    <div class="form-check form-switch form-switch-md mb-2">
                                        <label class="form-check-label" for="is-active-input">{{ $t('api_account.is_active') }}</label>
                                        <input type="checkbox" id="is-active-input" class="form-check-input" v-model="model.is_active" />
                                    </div>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </div>
                    <div class="card-footer text-end">
                        <b-button :disabled="loading" variant="primary" class="btn-rounded px-3" @click="save">
                            <i class="fas fa-save me-2"></i>{{ saveBtnText }}</b-button>
                    </div>
                </div>
            </b-form>
        </b-overlay>
    </Layout>
</template>

<script>
import Layout from "../../layouts/table";
import {mapGetters} from "vuex";
import Swal from "sweetalert2";

export default {
    name: "ApiAccountForm",

    components: {
        Layout,
    },

    data: function () {
        return {
            modifiedPassword: false,
            tempPassword: '',
            errors: {
                passwd: true
            }
        };
    },

    computed: {
        ...mapGetters({
            list: 'apiAccount/list',
            model: 'apiAccount/item',
            loading: 'apiAccount/loading',
        }),

        exists: function () {
            return !!this.$route.params.id
        },

        title: function () {
            return this.exists ? this.$t('api_account.modify', { id: this.model.m_id }) : this.$t('api_account.create_new')
        },

        saveBtnText: function () {
            return this.exists ? this.$t('api_account.save') : this.$t('api_account.create')
        },
    },

    methods: {
        save: function () {
            if (!this.exists && !this.model.passwd) {
                return this.passwordIsRequired();
            }

            this.errors = { passwd: this.errors.passwd };

            if (this.modifiedPassword) {
                this.warning().then(() => {
                    this.saveData();
                })
            } else {
                this.saveData();
            }
        },

        saveData: function () {
            const errorHandler = (err) => {
                const errors = {};

                err.data.errorData.forEach(field => {
                    errors[field] = false;
                });

                this.errors = Object.assign(errors);
            };

            this.exists
                ? this.$store.dispatch('apiAccount/update', this.model).then(response => {
                    if (response) {
                        this.$router.push({ path: this.$t('routes.api_accounts') });
                    }
                }).catch(errorHandler)
                : this.$store.dispatch('apiAccount/create', this.model).then(model => {
                    if (model.m_id) {
                        this.$router.push({ path: this.$t('routes.api_accounts') });
                    }
                }).catch(errorHandler);
        },

        fetch: function () {
            if (this.exists) {
                const action = this.list && this.list.length ? 'choose' : 'fetchOne';

                this.$store.dispatch(`apiAccount/${action}`, this.$route.params.id)
            }
        },

        editPassword: function () {
            this.modifiedPassword = true;
            this.tempPassword = this.model.passwd;
            this.model.passwd = '';
        },

        blurPassword: function () {
            if (!this.model.passwd) {
                this.model.passwd = this.tempPassword;
                if (this.modifiedPassword) {
                    this.modifiedPassword = false;
                }
            } else {
                this.warning();
            }
        },

        passwordIsRequired: function () {
            return Swal.fire({
                title: this.$t('api_account.password_is_required'),
                icon: 'warning',
                buttonsStyling: false,
                customClass: {
                    confirmButton: 'btn btn-danger btn-md me-1',
                    icon: 'text-danger border-danger'
                },
                confirmButtonText: 'Ok'
            }).then(() => {
                this.errors.passwd = false;
            });
        },

        warning: function () {
            if (!this.modifiedPassword) {
                return;
            }

            return Swal.fire({
                title: this.$t('api_account.key_attention'),
                html: `<p><small>${this.$t('api_account.warning')}</small><br><br><strong class="bg-primary py-1 px-3 border rounded text-white">${this.model.passwd || ''}</strong></p>`,
                icon: 'warning',
                buttonsStyling: false,
                customClass: {
                    confirmButton: 'btn btn-danger btn-md me-1',
                    icon: 'text-danger border-danger'
                },
                confirmButtonText: 'Ok'
            });
        },
    },

    watch: {
        title: function (val) {
            document.title = this.setTitle(val)
        },

        'model.passwd': {
            deep: true,
            immediate: true,
            handler: function (pass) {
                if (!pass) {
                    this.modifiedPassword = true;
                } else {
                    this.modifiedPassword = false;
                }
            }
        }
    },

    created() {
        this.fetch()
    },

    destroyed() {
        this.$store.dispatch('apiAccount/empty')
    }
}
</script>

<style scoped>
input::placeholder {
    color: lightgrey;
}
</style>
